/* ------------------------
Contact 
--------------------------*/
.inside-header.contact {
    background: url(../assets/img/contact-header.png) no-repeat 50% 50%;
    background-size: cover;
}


.contact-info {
    .fa {
        font-size: 3.2rem;
        color: $primary;
    }
}