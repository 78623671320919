/* ------------------------
Services
--------------------------*/
.inside-header.services {
    background: url(../assets/img/services-header.jpg) no-repeat 50% 50%;
    background-size: cover;
}

.services-page-intro {

    background: darken($primary, 15%);

    h1,
    h2,
    h3,
    p {
        color: white;
    }
}