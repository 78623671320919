/* ------------------------
Navigation 
--------------------------*/

.navbar {
    font-weight: 500;
    padding: 0;
    transition: all 0.4s ease;
    background: #fff;

    .navbar-brand {
        width: 150px;

        img {
            transition: all 0.6s ease;
            width: 100%;

        }
    }


    .navbar-nav {
        .dropdown {
            &:hover .dropdown-toggle {
                color: $primary;
            }
        }

        .dropdown-toggle:after {
            display: none !important;
            content: '\f107';
            font-family: 'FontAwesome';
            border: none;
            vertical-align: baseline;
        }

        .nav-link {
            line-height: 5.5rem;
            transition: all .3s;
            padding: 0.5rem 1rem;
            color: lighten($primary, 20%);
            text-transform: uppercase;
            font-weight: 700;

            &:hover,
            &:focus {
                color: $primary;
            }
        }

        .nav-item {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                width: 0;
                opacity: 0;
                height: 6px;
                background: $secondary;
                bottom: 0;
                transition: all .4s ease;
            }

            &:hover:after {
                width: 100%;
                opacity: 1;
            }
        }

        .nav-item.active {
            &:after {
                content: "";
                position: absolute;
                width: 75%;
                opacity: 1;
                height: 6px;
                background: $secondary;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%)
            }

            &:hover:after {
                width: 100%;
                opacity: 1;
            }
        }
    }

    .dropdown-menu {
        background: lighten($primary, 10%);
        animation: nav-drop .2s ease;
        margin: 0;
        border: 0;

        .dropdown-item {
            color: #fff;
            padding: 0.85rem 1.5rem;
            transition: all .2s;
            line-height: 1.5rem;


            &:hover,
            &:focus {
                background-color: darken($primary, 2%);
            }
        }
    }

    .navbar-nav {

        .active>.nav-link,
        .show>.nav-link {
            color: $secondary;
        }
    }
}

/* Animated sticky navbar when scroll up */
.stay-on-top {
    background: rgba(245, 245, 245, 1);

    .navbar-nav {


        .nav-link {
            line-height: 3.0rem;
        }
    }

    .navbar-brand img {
        width: 100%;
    }
}

@include media-breakpoint-down(md) {
    .navbar .navbar-nav {
        .nav-link {
            padding: 0.5rem 0.5rem;

        }
    }
}

/* For small divises */
@include media-breakpoint-down(xs);

@include media-breakpoint-down(sm) {
    .navbar {
        padding: 0;

        .container {
            max-width: 100%;
        }

        .navbar-collapse {
            background-color: darken($primary, 2%);
        }

        .navbar-brand {
            margin-left: 0.8rem;
            font-size: 1.2rem;
        }

        .navbar-toggler {
            border: none;
            outline: none;
        }

        .navbar-nav {
            li:last-child {
                margin-bottom: 10px;
            }

            li:first-child {
                margin-top: 10px;
            }

            .nav-link {
                line-height: 1.5rem;
                padding: 0.5rem 1rem;

                &:hover,
                &:focus {
                    color: lighten($primary, 40%);
                }
            }

            .active>.nav-link,
            .show>.nav-link {
                color: $secondary;
            }

            .dropdown {
                &:hover .dropdown-toggle {
                    color: lighten($primary, 40%);
                }
            }

            .nav-item.active:after,
            .nav-item:after {
                content: '';
                display: none;
            }
        }
    }


    .dropdown {
        &:hover:after {
            content: '';
            display: none;
        }

        .dropdown-menu .dropdown-item {
            padding: 0.6rem 1.5rem;
        }
    }
}