/* ------------------------
Portfolio 
--------------------------*/

.inside-header.about {
    background: url(../assets/img/about-header.jpg) no-repeat 50% 20%;
    background-size: cover;
}

.about-company {
    .image-container {
        img {
            width: 50%;
        }
    }
}


.our-values {
    background: $primary;

    .nav-pills .nav-link {
        &:hover {
            color: lighten($primary, 30%);
        }
    }



    h2,
    p {
        color: white;
    }
}

@include media-breakpoint-down(md) {
    .our-values {
        h4 {
            min-height: 20px;

            &:after {
                bottom: -10px;
                left: 0;

            }
        }
    }

    .about-company {
        .image-container {
            img {
                width: 50%;
            }
        }
    }
}

.why-consule {
    .tab-content {
        ul {
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
            }
        }
    }
}