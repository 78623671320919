/* ------------------------
Animations
--------------------------*/
@keyframes nav-drop {
    from {
        opacity: 0;
        left: -5px
        }
    to {
        opacity: 1;
        left: 0
        }
  }

  @keyframes dropdown-hover {
    from {
        opacity: 0;
        width: 0
        }
    to {
        opacity: 1;
        width: 100%;
        }
  }