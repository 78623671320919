/* ------------------------
Footer
--------------------------*/
.footer {
    background: $primary;
    color: white;

    .links {
        ul {
            list-style-type: none;
        }

        ul li,
        p {
            line-height: 1.8rem;
        }

        li a {
            color: white;
            transition: color .2s;
            text-transform: uppercase;

            &:hover {
                text-decoration: none;
                color: $secondary;
            }
        }

        h6 {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .about-company {
        i {
            font-size: 25px;
        }

        a {
            color: white;
            transition: color .2s;

            &:hover {
                color: $secondary
            }
        }

        img {
            width: 50%;
        }

    }

    .location {
        i {
            font-size: 18px;
        }

        a {
            color: white;
            transition: color .2s;

            &:hover {
                color: $secondary;
            }
        }

        p {
            line-height: 1.8rem;
        }


    }

    .copyright p {
        border-top: 1px solid rgba(255, 255, 255, .1);
    }
}