/* ------------------------
Global 
--------------------------*/

a {
    color: lighten($primary, 20%);

}

p,
li {
    line-height: 1.7rem;
}

p,
ul {
    margin-bottom: 0.2rem;
}

a:hover {
    color: $secondary;
    text-decoration: none
}

section {
    position: relative;
}

.inside-header {
    padding-bottom: 40px;
    height: 330px;
    border-bottom: 5px solid lighten($primary, 20%);
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 50%;
        left: 50%;
        height: 5px;
        background: $secondary;
        bottom: -5px;
    }

    h1 {
        color: white;
        position: relative;

        &::before {
            content: url(../assets/img/consule-symbol.png);
            position: absolute;
            top: -37px;

        }
    }
}