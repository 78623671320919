/* ------------------------
Portfolio 
--------------------------*/
.inside-header.portfolio {
    background: url(../assets/img/expertise-header.jpg) no-repeat 50% 20%;
    background-size: cover;
}


.section-fluid.expertise-landing{
    background: white;
        .overlay {
            h1{
                font-size:2.0rem!important;

                &:before{
                    content: "";
                    top:-35px!important;
                }
            }  
        }
}

.expertise-nav{
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    background: white;
    z-index: 100000;

    .ex-nav-item{
        text-align: center;
        text-transform: uppercase;
        border-bottom:2px solid transparent;
        transition: all .5s;
        margin: 0 1px;



        a{
            font-size:13px;
            font-weight: bold;
            transition: all .5s;

            &:hover{
                color:#000b18;
            }
        }

        &:hover{
            border-bottom:2px solid $secondary;

            & a{
                color:#000b18;  
            }
        }

    }

    .ex-nav-item.active{
        border-bottom:2px solid $secondary;

        & a{
            color: $secondary; 
        }
    }
}

.technology-areas {

    img {
        width: 70%;
        margin-bottom: 20px;
    }

    .sticky-top {
        top: 90px;
    }
}
