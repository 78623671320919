/* ------------------------
Home 
--------------------------*/
main[role="main"] {
  height: 100vh;
}

/* Main Slder */
.main-slider {
  height: 75h;
}

#bs-slider-home {
  .carousel-indicators li {
    width: 15px;
    height: 15px;
    border-radius: 8px;
    border: 0;
    opacity: 1;
  }

  .carousel-indicators .active {
    background: $secondary;
  }

  .carousel-inner {
    height: 75vh;

    .carousel-item {

      background: url("../assets/img/slider-image-cities.jpg") no-repeat;
      height: 100%;
      background-size: cover;

      .carousel-caption {
        bottom: 5%;
        width: 80%;
        transform: translate(-50%);
        left: 50%;

        h1 {
          color: white;
          font-weight: 700;
          font-size: 2.0rem;
        }
      }

      .btn {
        margin-top: 20px;
        position: relative;
      }
    }
  }
}

/* Company intro */
.intro {
  background: $primary;
  border-top: 7px solid lighten($secondary, 2%);

  &:after {
    content: "";
    width: 50%;
    height: 7px;
    background: lighten($primary, 10%);
    position: absolute;
    top: 0;
  }

  .lead {
    color: white;
    margin-bottom: 0;
    line-height: 2.5rem;
    opacity: .6;
  }
}

/* Services intro */
.services-intro {

  .services-heading {
    position: relative;
    display: block;
    line-height: 2.5rem;

    &:after {
      content: "";
      width: 100px;
      height: 2px;
      background: $secondary;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: -30px;
    }
  }

  .sd-body {
    .btn {
      margin: 60px 0 0;
      position: relative;
    }
  }

  .s-content {
    z-index: 1;
    background: white;
    height: 15px;
  }

  .card {
    border: none;

    .card-body {
      line-height: 2.3rem;
      display: flex;
      flex-direction: column;

    }
  }
}

@include media-breakpoint-down(xs);

@include media-breakpoint-down(md) {
  .services-intro .card .card-body h2 {
    min-height: fit-content;
  }

  .services-intro .card .card-text:before {
    content: "";
    top: -14px;
  }
}

/* Technology domains */
.section-fluid {
  background: $primary;

  h2,
  p {
    color: white;
  }

  .tech-domain-intro {
    h2 {
      margin-bottom: 85px;
      position: relative;
      display: block;
      line-height: 2.5rem;

      &:after {
        content: "";
        width: 100px;
        height: 2px;
        background: $secondary;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: -30px;
      }
    }
  }

  .tech-domain-areas {
    .ts {
      margin-bottom: 30px;
    }

    .btn {
      margin: 30px 0 0;
    }

    .hovereffect {
      width: 100%;
      height: 100%;
      float: left;
      overflow: hidden;
      position: relative;
      text-align: center;
      background: #0C275A;
      // background: -webkit-linear-gradient(45deg, #16296B 20%, #EED104 100%);
      // background: linear-gradient(45deg, #16296B 20%,#EED104 100%);

      .overlay,
      .overlay-xs {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 2.8em 1.5em 1.5em 1.5em;
        text-align: left;

        &:before {
          position: absolute;
          top: 5px;
          right: 5px;
          bottom: 5px;
          left: 5px;
          border: 1px solid #fff;
          content: '';
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
          transition: opacity 0.35s, transform 0.45s;
          -webkit-transform: translate3d(-3px, 0, 0);
          transform: translate3d(-3px, 0, 0);
        }

        h1 {
          text-transform: uppercase;
          color: #fff;
          position: relative;
          font-size: 1.2rem;
          background-color: transparent;
          padding: 0 0 0 0;
          text-align: left;
          margin: 0;
          color: $secondary;

          &:before {
            content: url(../assets/img/consule-symbol-small.png);
            position: absolute;
            left: 0;
            top: -21px;
            transition: all .2s;
          }
        }


        p {
          font-size: 1.0rem;
          margin: 0;
          // flex-grow:1;
          color: white;
          line-height: 1.2rem;
        }

        i {
          font-size: 40px;
          margin-top: 10px;
          display: block;
          color: lighten($primary, 40%);
        }

        h1,
        p,
        i {
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
          transition: opacity 0.35s, transform 0.45s;
          -webkit-transform: translate3d(-10px, 0, 0);
          transform: translate3d(-10px, 0, 0);
        }
      }

      img {
        display: block;
        position: relative;
        max-width: none;
        width: calc(100% + 60px);
        -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
        transition: opacity 0.35s, transform 0.45s;
        -webkit-transform: translate3d(-40px, 0, 0);
        transform: translate3d(-40px, 0, 0);
      }



      &:hover img {
        opacity: .1;
        filter: alpha(opacity=60);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      &:hover h1,
      &:hover p {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }

      &:hover i {
        opacity: 1;
        filter: alpha(opacity=100);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        transition-delay: .2s;
      }

      &:hover .overlay:before {
        opacity: .3;
        filter: alpha(opacity=100);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

/* For small devises */
@include media-breakpoint-down(md) {
  .section-fluid .tech-domain-areas .hovereffect .overlay h1 {
    font-size: 1.0rem;
  }

  .section-fluid .tech-domain-areas .hovereffect .overlay p,
  .section-fluid .tech-domain-areas .hovereffect .overlay i {
    line-height: 1.2rem;
  }

}

@include media-breakpoint-down(xs) {
  .section-fluid .tech-domain-areas .hovereffect .overlay h1 {
    font-size: 1.5rem;
    opacity: 1;
  }

  .section-fluid .tech-domain-areas .hovereffect .overlay p,
  .section-fluid .tech-domain-areas .hovereffect .overlay i {
    opacity: 1;
    line-height: 1.2rem;
  }

  .section-fluid .tech-domain-areas .hovereffect .overlay-xs {
    opacity: .6;
    background: #0C275A;
  }

}








/* Default Home */