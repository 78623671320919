/* ------------------------
HTML Components
--------------------------*/
// Button
.btn,
.btn-lg {
    padding: 1.0rem 3.5rem 1.0rem 2.6rem;
    background: lighten($primary, 20%);
    border: 0;
    transition: all .5s;
    min-width: 13.0rem;
}

.btn {
    margin: 60px 0 0;
    position: relative;

    &:after {
        content: url(../assets/img/consule-symbol-small.png);
        position: absolute;
        right: 30px;
        top: 19px;
        transition: all .2s;
    }

    &:hover:after {
        content: url(../assets/img/consule-symbol-small.png);
        right: 27px;
        position: absolute;
    }

}

.nav-pills {
    .nav-link {
        border: 2px solid transparent;
        transition: all .5s;

        &:hover {
            border-bottom: 2px solid $secondary;
            color: darken($primary, 20%);
        }

        &.active {
            border-bottom: 2px solid $secondary;
            color: $secondary;
            background: transparent;
        }
    }
}