// Import mixins
@import "helpers/mixins";

// Import custom variables
@import "helpers/custom-variables";

// Import css animations
@import "helpers/animations";

// Import Global <- define global css classes
@import "partials/global";

// Import Laouts <- define styles for layout classes
@import "partials/layout";

// Import Header <- define styles for header
@import "partials/header";

// Import Sidebar <- Define styles for sidebar
@import "partials/sidebar";

// Import Navigation <- Define styles for Navigations
@import "partials/navigation";

// Import Footer <- Define styles for footer
@import "partials/footer";

// Import components <- Define styles HTML components
@import "partials/components";

// Import Pages <- Define styles for pages. import all the pages into pages.scss(Check pages.scss)
@import "pages/pages";

// Import Hacks <- Things that you think which could be done better
@import "partials/shame";