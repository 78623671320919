// Import Pages
// Homepage
@import "home";

//About
@import "about";

// Contact
@import "contact";

// Products
@import "products";

// Servises
@import "services";

// Careers
@import "careers";

// Blog
@import "blog";

// Portfolio 
@import "portfolio";