// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules
// =============================================================================

// Colors
$primary: rgb(0, 59, 126);
$secondary: rgb(255, 174, 0);
$body-bg: #ffffff;
$body-color: rgb(135, 135, 135);
$heading-color :$primary;

// Fonts
$font-family-base: 'Lato',
sans-serif;

// Headings color
h1,
h2,
h3 {
  color: lighten($heading-color, 10%);
}

// Link Colors
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);

// Borders
$border-radius: 2px !default;
$border-radius-lg: 0 !default;
$border-radius-sm: 0 !default;

// button
$btn-border-radius: 2.0rem !default;
$btn-border-radius-lg: 2.5rem !default;
$btn-border-radius-sm: 1.5rem !default;

// Spaces
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
1: ($spacer * .25),
2: ($spacer * .5),
3: $spacer,
4: ($spacer * 1.5),
5: ($spacer * 3),
6: ($spacer * 5),
7: ($spacer * 10)),
$spacers);

// define your own variables here
// =============================================================================