// Mixins

// Bootstrap responsive breakpoints
// https://getbootstrap.com/docs/4.1/layout/overview/
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/mixins/_breakpoints";
//  usage
//   No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
//   @include media-breakpoint-up(sm) { ... }
//   @include media-breakpoint-up(md) { ... }
//   @include media-breakpoint-up(lg) { ... }
//   @include media-breakpoint-up(xl) { ... }

//  Example: Hide starting at `min-width: 0`, and then show at the `sm` breakpoint
//  .custom-class {
//      display: none;
//  }
//  @include media-breakpoint-up(sm) {
//      .custom-class {
//          display: block;
//      }
//  }